import React from 'react';

const AIResearchAndDevelopment = () => {
  return (
    <section className="bg-gray-900 text-white py-20 w-full flex items-center justify-center min-h-screen">
      <div className="w-full max-w-6xl px-6 text-left">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
          AI Research and Development
        </h2>
        <p className="text-lg md:text-xl mb-8">
          Data Frontier is at the forefront of AI research, constantly pushing the boundaries of what's possible. Our focus on developing cutting-edge AI models, including:
        </p>
        <ul className="list-disc list-inside text-lg md:text-xl space-y-4">
          <li>
            <strong>Large Language Models (LLMs):</strong> We build powerful LLMs that can understand and generate human-like text, enabling natural language processing applications such as chatbot development and content generation.
          </li>
          <li>
            <strong>Generative AI (GenAI):</strong> We specialize in developing GenAI models that can create new content, such as images, videos, and music, offering creative solutions for various industries.
          </li>
          <li>
            <strong>Computer Vision:</strong> We utilize AI models to analyze and interpret images and videos, enabling applications such as object recognition, facial recognition, and image classification.
          </li>
          <li>
            <strong>Deep Learning:</strong> We leverage deep learning algorithms to develop highly accurate AI models for complex tasks, such as fraud detection, medical diagnosis, and natural language understanding.
          </li>
        </ul>
        <p className="text-lg md:text-xl mt-8">
          We believe that AI has the potential to revolutionize businesses and society as a whole, and we are committed to using our expertise to drive positive change.
        </p>
      </div>
    </section>
  );
}

export default AIResearchAndDevelopment;
