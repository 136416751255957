import React from 'react';
import Header from '../components/Header';

const ShippingDeliveryPolicy = () => {
  return (
    <>
    <Header />
    <section className="bg-gray-900 w-full text-white py-20 flex items-center justify-center min-h-screen">
      <div className="w-full max-w-6xl px-6 text-left">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
        Shipping & Delivery Policy
        </h2>
        <p className="text-lg md:text-xl mb-8">
          Shipping is not applicable for business
        </p>
        
      </div>
    </section>
    </>
  );
};

export default ShippingDeliveryPolicy;
