import React from 'react';
import Header from '../components/Header';

const RefundPolicy = () => {
  return (
    <>
    <Header />
    <section className="bg-gray-900 w-full text-white py-20 flex items-center justify-center min-h-screen">
      <div className="w-full max-w-6xl px-6 text-left">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
          Refund Policy
        </h2>
        <p className="text-lg md:text-xl mb-8">
          <strong>No refunds:</strong> We kindly ask users to note that once a purchase is completed and app features are accessed, we are unable to provide refunds. We encourage you to review your purchase carefully before proceeding, as all sales are final.
        </p>
        <p className="text-lg md:text-xl">
        If you have any questions or need assistance, our support team is here to help and ensure you have a smooth experience.
        </p>
      </div>
    </section>
    </>
  );
};

export default RefundPolicy;
