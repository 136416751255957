import React from 'react';
import logo from '../assets/logos/df-short-white.png'

const Testimonials = () => {
  return (
    <section className="bg-gray-900 w-full text-white py-20 flex items-center justify-center min-h-screen">
      <div className="w-full max-w-6xl flex flex-col md:flex-row items-center">
        <div className="md:w-1/2">
          <img src={logo} alt="Testimonials Background" className="w-full h-full object-cover"/>
        </div>
        <div className="md:w-1/2 p-6 text-left">
          <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
            Testimonials
          </h2>
          <p className="text-lg md:text-xl mb-8">
            Our clients are the best testament to our commitment to excellence. We are proud to have earned the trust and confidence of leading businesses across diverse industries. Here are a few testimonials from our satisfied clients:
          </p>
          <div className="space-y-8">
            <blockquote className="border-l-4 border-blue-400 pl-4">
              <p className="text-lg md:text-xl mb-4">
                "Data Frontier has been an invaluable partner in helping us transform our business through data-driven insights. Their team of experts has provided us with innovative solutions that have driven significant improvements in our operations and customer satisfaction."
              </p>
              <cite className="text-lg md:text-xl">- CEO of a Fortune 500 company</cite>
            </blockquote>
            <blockquote className="border-l-4 border-blue-400 pl-4">
              <p className="text-lg md:text-xl mb-4">
                "We were impressed with Data Frontier's deep understanding of our business and their ability to develop customized solutions that met our specific needs. Their team's expertise in AI has enabled us to gain a competitive edge in our industry."
              </p>
              <cite className="text-lg md:text-xl">- Director of Innovation at a leading technology company</cite>
            </blockquote>
          </div>
          <p className="text-lg md:text-xl mt-8">
            We are committed to providing our clients with the best possible experience and exceeding their expectations.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
