import React from 'react';

const ValuesSection = () => {
  return (
    <section className="bg-gray-900 text-white py-20 w-full flex items-center justify-center min-h-screen">
      <div className="max-w-6xl px-6 text-left">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
          Our Values
        </h2>
        <p className="text-lg md:text-xl mb-8">
          Our values are the foundation of our company culture and guide our interactions with our clients, partners, and employees. We are committed to:
        </p>
        <ul className="list-disc list-inside text-lg md:text-xl space-y-8">
          <li>
            <strong>Innovation:</strong> We constantly strive to explore new ideas, technologies, and methodologies to deliver cutting-edge solutions.
          </li>
          <li>
            <strong>Excellence:</strong> We are dedicated to delivering the highest quality work and exceeding our clients' expectations.
          </li>
          <li>
            <strong>Collaboration:</strong> We believe that teamwork is essential for achieving great results. We foster a collaborative environment where everyone's voice is heard and valued.
          </li>
          <li>
            <strong>Integrity:</strong> We operate with honesty and transparency, building trust with our clients and partners.
          </li>
          <li>
            <strong>Customer Focus:</strong> We prioritize our clients' needs and goals, providing customized solutions that deliver tangible results.
          </li>
        </ul>
      </div>
    </section>
  );
}

export default ValuesSection;
