import React from "react";


const NumBox = ({value}) => {
    return(
        <div className="bg-gray-800 h-12 w-24 rounded-xl flex items-center justify-center text-2xl font-bold text-blue-400">
            {value}
            </div>
    )
}

export default NumBox;