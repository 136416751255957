import React from 'react';

const OurExpertise = () => {
  return (
    <section className="bg-gray-900 w-full text-white py-20 flex items-center justify-center min-h-screen">
      <div className="w-full max-w-6xl px-6 text-left">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
          Our Expertise
        </h2>
        <p className="text-lg md:text-xl mb-8">
          Data Frontier is a team of highly skilled and experienced professionals with a deep understanding of data science, AI research, and data engineering. Our team includes:
        </p>
        <ul className="list-disc list-inside text-lg md:text-xl space-y-4">
          <li>
            <strong>Data Scientists:</strong> Our data scientists have expertise in statistical modeling, machine learning, deep learning, data mining, and data visualization.
          </li>
          <li>
            <strong>AI Researchers:</strong> Our AI researchers are at the forefront of innovation, developing cutting-edge AI models and exploring new applications of AI.
          </li>
          <li>
            <strong>Data Engineers:</strong> Our data engineers have expertise in data pipeline design, data warehouse and data lake solutions, data integration and transformation, data security and governance, and cloud data solutions.
          </li>
        </ul>
        <p className="text-lg md:text-xl mt-8">
          Our team is passionate about data and committed to providing our clients with the highest quality solutions.
        </p>
      </div>
    </section>
  );
}

export default OurExpertise;
