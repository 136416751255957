import NumBox from "./NumBox"

export const CardBox = ({itemNum, heading, content}) => {
    return (
        <div className="flex items-start space-x-4 md:w-1/2 mb-4 md:mb-0">
              <NumBox value={itemNum} />
              <div>
                <h3 className="text-xl font-bold text-blue-400">
                  {heading}
                </h3>
                <p className="text-lg md:text-xl">
                  {content}
                </p>
              </div>
            </div>
    )
}

