import React from 'react';

const OurServices = () => {
  return (
    <section className="bg-gray-900 w-full text-white py-20 flex items-center justify-center min-h-screen">
      <div className="w-full max-w-6xl px-6 text-left">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
          Our Services
        </h2>
        <p className="text-lg md:text-xl mb-8">
          Data Frontier offers a comprehensive suite of services to meet the diverse needs of our clients. Our services include:
        </p>
        <ul className="list-disc list-inside text-lg md:text-xl space-y-4">
          <li>
            <strong>Data Strategy Consulting:</strong> We help businesses define their data strategy and develop a roadmap for achieving their data-driven goals.
          </li>
          <li>
            <strong>Data Acquisition and Integration:</strong> We assist businesses in collecting, cleaning, and integrating data from various sources.
          </li>
          <li>
            <strong>Data Modeling and Analysis:</strong> We develop advanced statistical models and machine learning algorithms to uncover insights and predict outcomes.
          </li>
          <li>
            <strong>AI Model Development and Training:</strong> We build, train, and deploy custom AI models tailored to specific business needs.
          </li>
          <li>
            <strong>Data Visualization and Reporting:</strong> We create compelling data visualizations and reports that communicate insights effectively to stakeholders.
          </li>
          <li>
            <strong>Data Governance and Compliance:</strong> We help businesses establish data governance frameworks and ensure compliance with relevant regulations.
          </li>
          <li>
            <strong>Data Engineering and Infrastructure Solutions:</strong> We design and implement robust data pipelines and architectures to ensure seamless data flow and accessibility.
          </li>
        </ul>
        <p className="text-lg md:text-xl mt-8">
          Our services are tailored to address the unique needs of each client, ensuring that our solutions deliver tangible results and drive business impact.
        </p>
      </div>
    </section>
  );
}

export default OurServices;
