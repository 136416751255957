import React from 'react';
import img from '../assets/images/pi-mission.png'

const MissionSection = () => {
  return (
    <section className="bg-gradient-to-r from-gray-800 to-gray-900 w-full min-h-screen text-white py-20">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 p-6 text-left">
          <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
            Our Mission
          </h2>
          <p className="text-lg md:text-xl mb-8">
            Our mission is to empower businesses with the knowledge, tools, and technologies they need to harness the power of data. We strive to be a trusted partner, guiding our clients on their data-driven journey and helping them achieve their strategic goals.
          </p>
          <p className="text-lg md:text-xl">
            We believe that data science and AI have the potential to revolutionize industries and improve lives. Through our work, we aim to contribute to a world where data is used responsibly and ethically to create a positive impact.
          </p>
        </div>
        <div className="md:w-1/2">
          <img src={img} alt="Mission" className="w-auto h-3/4 object-contain"/>
        </div>
      </div>
    </section>
  );
}

export default MissionSection;
