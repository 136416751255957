import React from 'react';

const DataEngineeringServices = () => {
  return (
    <section className="bg-gray-900 w-full text-white py-20 flex items-center justify-center min-h-screen">
      <div className="w-full max-w-6xl px-6 text-left">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
          Data Engineering Services
        </h2>
        <p className="text-lg md:text-xl mb-8">
          Data engineering is the foundation for effective data science and AI. Our comprehensive data engineering services ensure that data is collected, stored, processed, and accessed efficiently and securely. We offer:
        </p>
        <ul className="list-disc list-inside text-lg md:text-xl space-y-4">
          <li>
            <strong>Data Pipeline Design and Implementation:</strong> We design and build robust data pipelines to ensure seamless data flow from various sources to target systems.
          </li>
          <li>
            <strong>Data Warehouse and Data Lake Solutions:</strong> We design and implement scalable data warehouses and data lakes to store and manage large volumes of data efficiently.
          </li>
          <li>
            <strong>Data Integration and Transformation:</strong> We integrate data from various sources and transform it into a consistent format for analysis and reporting.
          </li>
          <li>
            <strong>Data Security and Governance:</strong> We implement robust data security measures and governance policies to protect sensitive data and comply with relevant regulations.
          </li>
          <li>
            <strong>Cloud Data Solutions:</strong> We leverage cloud platforms like AWS, Azure, and GCP to provide scalable and cost-effective data storage and processing solutions.
          </li>
        </ul>
        <p className="text-lg md:text-xl mt-8">
          Our team of data engineers has deep expertise in various data technologies, enabling us to provide tailored solutions for any business challenge.
        </p>
      </div>
    </section>
  );
}

export default DataEngineeringServices;
