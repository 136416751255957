import React from 'react';

const CaseStudies = () => {
  return (
    <section className="bg-gray-900 w-full text-white py-20 flex items-center justify-center min-h-screen">
      <div className="w-full max-w-6xl px-6 text-left">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
          Case Studies
        </h2>
        <p className="text-lg md:text-xl mb-8">
          We have a proven track record of success in delivering data-driven solutions that drive business impact. Our case studies showcase how we have helped various companies achieve their goals through our data science, AI research, and data engineering services. We have worked with organizations across different industries, including:
        </p>
        <ul className="list-disc list-inside text-lg md:text-xl space-y-4">
          <li>
            <strong>Healthcare:</strong> We helped a healthcare provider improve patient outcomes by developing a predictive model to identify high-risk patients.
          </li>
          <li>
            <strong>Financial Services:</strong> We helped a financial institution reduce fraud losses by implementing a machine learning model to detect fraudulent transactions.
          </li>
          <li>
            <strong>Retail:</strong> We helped a retailer improve customer satisfaction by using data analytics to personalize recommendations and optimize marketing campaigns.
          </li>
          <li>
            <strong>Manufacturing:</strong> We helped a manufacturer optimize production processes by using AI to predict equipment failures and improve efficiency.
          </li>
        </ul>
        <p className="text-lg md:text-xl mt-8">
          We are proud of our accomplishments and are committed to continuing to deliver innovative solutions that drive business value.
        </p>
      </div>
    </section>
  );
}

export default CaseStudies;
