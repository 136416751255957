import React from 'react';
import photo from '../assets/images/maddy-edited.JPG'

const HeroSection = () => {
  return (
    <section className="bg-gradient-to-r from-gray-800 to-gray-900 w-full min-h-screen flex items-center justify-center text-white">
      <div className="max-w-6xl p-6 my-10">
        <h1 className="text-4xl md:text-7xl font-bold mb-6 text-heading-blue text-left">
          Data Frontier: Pioneering Excellence in Data Science and Engineering
        </h1>
        
      </div>
    </section>
  );
}

export default HeroSection;
