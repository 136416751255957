import React from 'react';

const DataScienceSolutions = () => {
  return (
    <section className="bg-gray-900 text-white py-20 w-full flex items-center justify-center min-h-screen">
      <div className="w-full max-w-6xl px-4 text-left">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
          Data Science Solutions
        </h2>
        <p className="text-lg md:text-xl mb-8">
          Data science is the backbone of our services, enabling businesses to extract meaningful insights from their data. Our comprehensive data science solutions cover a wide range of applications, including:
        </p>
        <ul className="list-disc list-inside text-lg md:text-xl space-y-4">
          <li>
            <strong>Predictive Analytics:</strong> We leverage advanced statistical models and machine learning algorithms to forecast future trends and predict outcomes.
          </li>
          <li>
            <strong>Machine Learning:</strong> We develop and implement machine learning models to automate tasks, improve decision-making, and unlock new possibilities.
          </li>
          <li>
            <strong>Data Visualization:</strong> We create compelling data visualizations that help businesses understand complex data and communicate insights effectively.
          </li>
          <li>
            <strong>Data Exploration and Analysis:</strong> We use data mining techniques to discover hidden patterns, identify relationships, and gain a deeper understanding of data.
          </li>
          <li>
            <strong>Data Quality Management:</strong> We ensure the accuracy, completeness, and consistency of data to ensure the reliability of our insights.
          </li>
        </ul>
        <p className="text-lg md:text-xl mt-8">
          Our team of data scientists has extensive experience in various industries, enabling us to provide tailored solutions that address specific business challenges.
        </p>
      </div>
    </section>
  );
}

export default DataScienceSolutions;
