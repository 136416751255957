import React from 'react';
import Header from '../components/Header';

const TermsAndConditions = () => {
  return (
    <>
    <Header />
    <section className="bg-gray-900 w-full text-white py-20 flex items-center justify-center min-h-screen">
      <div className="w-full max-w-6xl px-6 text-left">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
          Terms and Conditions
        </h2>
        <p className="text-lg md:text-xl mb-8">
          By using our website, you agree to comply with and be bound by the following terms and conditions of use.
        </p>
        <ul className="list-disc list-inside text-lg md:text-xl space-y-4">
          <li>
            All content on this website is for informational purposes only and may be subject to change without notice.
          </li>
          <li>
            Unauthorized use of this website may give rise to a claim for damages or be a criminal offense.
          </li>
          <li>
            Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable.
          </li>
          <li>
            These terms are governed by and construed in accordance with the laws of India only.
          </li>
        </ul>
        <p className="text-lg md:text-xl mt-8">
          If you do not agree with these terms, please refrain from using our services.
        </p>
      </div>
    </section>
    </>
  );
};

export default TermsAndConditions;
