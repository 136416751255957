import React from "react";
import NumBox from "./NumBox";
import { CardBox } from "./CardBox";

const WhyChooseUs = () => {
  return (
    <section className="bg-gray-900 text-white py-20 w-full flex items-center justify-center min-h-screen">
      <div className="w-full max-w-6xl px-6 text-left">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
          Why Choose Data Frontier?
        </h2>
        <p className="text-lg md:text-xl mb-8">
          Data Frontier is the trusted partner for businesses seeking to
          leverage data for strategic advantage. Here are some key reasons why
          you should choose us:
        </p>
        <div className=" flex flex-col items-center justify-center gap-10">
          <div className="w-full flex flex-col md:flex-row items-center justify-evenly">
            <CardBox
              heading="Innovative Solutions"
              content="We develop custom solutions that address your unique business needs and leverage the latest advancements in data science and AI."
              itemNum="1"
            />
            <CardBox
              heading="Expert Team"
              content="Our team of experienced data scientists, AI researchers, and
                  data engineers brings a wealth of knowledge and expertise to
                  every project."
              itemNum="2"
            />
          </div>
          <div className="w-full flex flex-col md:flex-row items-center justify-evenly">
            <CardBox
              heading="Customer-Centric Approach"
              content="We prioritize your goals and challenges, ensuring that our
                  solutions deliver measurable results and drive long-term
                  success."
              itemNum="3"
            />
            <CardBox
              heading="Cutting-Edge Technology"
              content="We utilize the most advanced tools and technologies to stay
                  ahead of the curve, giving you the competitive edge you need
                  to thrive."
              itemNum="4"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
