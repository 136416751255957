import React from 'react';
import Header from '../components/Header';

const PrivacyPolicy = () => {
  return (
    <>
    <Header />
    <section className="bg-gray-900 w-full text-white py-20 flex items-center justify-center min-h-screen">
      <div className="w-full max-w-6xl px-6 text-left">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
          Privacy Policy
        </h2>
        <p className="text-lg md:text-xl mb-8">
          Your privacy is important to us. This privacy policy outlines the types of personal information we collect and how we use, disclose, and protect that information.
        </p>
        <ul className="list-disc list-inside text-lg md:text-xl space-y-4">
          <li>
            We collect personal information when you voluntarily provide it, such as when you sign up for our services or subscribe to our newsletters.
          </li>
          <li>
            We may use your personal information to improve our services, send promotional emails, and ensure the security of our website.
          </li>
          <li>
            We do not sell or share your personal information with third parties, except as required by law.
          </li>
          <li>
            You have the right to access, update, or delete your personal information at any time by contacting us.
          </li>
        </ul>
        <p className="text-lg md:text-xl mt-8">
          We are committed to ensuring that your information is secure and protected in accordance with applicable laws.
        </p>
      </div>
    </section>
    </>
  );
};

export default PrivacyPolicy;
