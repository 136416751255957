import React, { useState } from 'react';
import logo from '../assets/logos/df-white.png'; 
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom'; // For routing to static pages

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-gray-900 text-white p-4">
      <div className="container mx-auto flex justify-between items-center px-10">
        <div className="flex items-center">
          <img src={logo} alt="Data Frontier Logo" className="h-16 w-auto mr-2"/>
        </div>
        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-4">
          <NavLink 
            to="/" 
            className={({ isActive }) => 
              isActive ? 'underline text-blue-400' : 'cursor-pointer hover:text-blue-400'
            }
          >
            Home
          </NavLink>
          {/* <Link to="our-services" smooth={true} duration={1000} className="cursor-pointer hover:text-blue-400">Services</Link>
          <Link to="case-studies" smooth={true} duration={1000} className="cursor-pointer hover:text-blue-400">Case Studies</Link> */}
          <NavLink 
            to="/contact-us" 
            className={({ isActive }) => 
              isActive ? 'underline text-blue-400' : 'hover:text-blue-400'
            }
          >
            Contact
          </NavLink>
          {/* New links */}
          <NavLink 
            to="/privacy-policy" 
            className={({ isActive }) => 
              isActive ? 'underline text-blue-400' : 'hover:text-blue-400'
            }
          >
            Privacy Policy
          </NavLink>
          <NavLink 
            to="/terms-and-conditions" 
            className={({ isActive }) => 
              isActive ? 'underline text-blue-400' : 'hover:text-blue-400'
            }
          >
            Terms and Conditions
          </NavLink>
          <NavLink 
            to="/refund-policy" 
            className={({ isActive }) => 
              isActive ? 'underline text-blue-400' : 'hover:text-blue-400'
            }
          >
            Refund Policy
          </NavLink>
          <NavLink 
            to="/shipping-policy" 
            className={({ isActive }) => 
              isActive ? 'underline text-blue-400' : 'hover:text-blue-400'
            }
          >
            Shipping Policy
          </NavLink>
          
        </nav>
        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-blue-400 focus:outline-none">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
      </div>
      {/* Mobile Navigation */}
      {isOpen && (
        <nav className="md:hidden bg-gray-800 py-4">
          <NavLink 
            onClick={toggleMenu} 
            to="/"  
            className={({ isActive }) => 
              isActive ? 'underline text-blue-400' : 'block px-4 py-2 text-white hover:text-blue-400'
            }
          >
            Home
          </NavLink>
          {/* <Link onClick={toggleMenu} to="our-services" smooth={true} duration={1000} className="block px-4 py-2 text-white hover:text-blue-400">Services</Link>
          <Link onClick={toggleMenu} to="case-studies" smooth={true} duration={1000} className="block px-4 py-2 text-white hover:text-blue-400">Case Studies</Link> */}
          <NavLink 
            onClick={toggleMenu} 
            to="/contact-us" 
            className={({ isActive }) => 
              isActive ? 'underline text-blue-400' : 'block px-4 py-2 text-white hover:text-blue-400'
            }
          >
            Contact
          </NavLink>
          {/* New links for mobile */}
          <NavLink 
            onClick={toggleMenu} 
            to="/privacy-policy" 
            className={({ isActive }) => 
              isActive ? 'underline text-blue-400' : 'block px-4 py-2 text-white hover:text-blue-400'
            }
          >
            Privacy Policy
          </NavLink>
          <NavLink 
            onClick={toggleMenu} 
            to="/terms-and-conditions" 
            className={({ isActive }) => 
              isActive ? 'underline text-blue-400' : 'block px-4 py-2 text-white hover:text-blue-400'
            }
          >
            Terms and Conditions
          </NavLink>
          <NavLink 
            onClick={toggleMenu} 
            to="/refund-policy" 
            className={({ isActive }) => 
              isActive ? 'underline text-blue-400' : 'block px-4 py-2 text-white hover:text-blue-400'
            }
          >
            Refund Policy
          </NavLink>
          <NavLink 
            onClick={toggleMenu} 
            to="/shipping-policy" 
            className={({ isActive }) => 
              isActive ? 'underline text-blue-400' : 'block px-4 py-2 text-white hover:text-blue-400'
            }
          >
            Shipping Policy
          </NavLink>
        </nav>
      )}
    </header>
  );
};

export default Header;
