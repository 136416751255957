import React from 'react';

const JoinUs = () => {
  return (
    <section className="bg-gray-900 w-full text-white py-20 flex items-center justify-center min-h-screen">
      <div className="w-full max-w-6xl px-6 text-left">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
          Join the Data Frontier Revolution
        </h2>
        <p className="text-lg md:text-xl mb-8">
          Subscribe to our newsletter and stay updated on the latest trends, insights, and innovations in data science, AI research, and data engineering. Join the Data Frontier revolution and lead your industry with data-driven excellence.
        </p>
      </div>
    </section>
  );
}

export default JoinUs;
