import React from "react";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";

const ContactUs = () => {
  return (
    <section className="bg-gray-900 w-full text-white py-20 flex items-center justify-center min-h-screen">
      <div className="w-full max-w-6xl px-6 text-left">
      <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
          Contact Us
        </h2>
        <div className="bg-gray-900 text-white p-6 rounded-lg shadow-lg max-w-sm mx-auto">
          <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
          <p className="text-gray-400 mb-6">
            Any question? We would be happy to help you!
          </p>
          <div className="flex items-center bg-gray-800 p-3 rounded-lg mb-4">
            <FaPhoneAlt className="text-blue-400 mr-3" />
            <a href="tel:+919971630802" className="text-white">+91 99716 30802</a>
          </div>
          <div className="flex items-center bg-gray-700 p-3 rounded-lg mb-4">
            <FaEnvelope className="text-blue-400 mr-3" />
            <a href="mailto:contact@datafrontier.com" className="text-white">contact@datafrontier.com</a>
          </div>
          <div className="flex items-center bg-gray-800 p-3 rounded-lg">
            <FaMapMarkerAlt className="text-blue-400 mr-3" />
            <a href="https://maps.app.goo.gl/LJWodef9Cbc5DX7h6" className="text-white">BHIVE Premium HSR Campus</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
