import React from 'react';

const AboutUs = () => {
  return (
    <section className='bg-gray-900 w-full min-h-screen text-white py-20'>
      <div className=" max-w-6xl flex flex-col gap-y-10 items-start justify-center text-white min-h-screen mx-auto px-6 w-3/4 ">
        <h2 className="text-4xl bg- red-200md:text-5xl font-bold mb-6 text-blue-400 text-left flex flex-row items-center">
          About Data Frontier
        </h2>
        <p className="text-lg md:text-xl mb-8 text-left">
          At Data Frontier, we are driven by a passion for harnessing the power of data to solve complex challenges and drive business success. Our team of experienced data scientists, AI researchers, and data engineers are dedicated to pushing the boundaries of what's possible in the field of data analytics. We combine our expertise with cutting-edge technologies and methodologies to create customized solutions tailored to the specific needs of our clients.
        </p>
        <p className="text-lg md:text-xl text-left">
          We believe that data is more than just information; it's a powerful tool that can be used to unlock insights, optimize operations, and drive innovation. By leveraging our expertise and innovative solutions, we empower businesses to make informed decisions, improve efficiency, and gain a competitive edge in today's data-driven world.
        </p>
      </div>
      </section>
    
  );
}

export default AboutUs;
